.public-DraftEditor-content .align-right .public-DraftStyleDefault-ltr {
  text-align: right !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-left .public-DraftStyleDefault-ltr {
  text-align: left !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-center .public-DraftStyleDefault-ltr {
  text-align: center !important; /* Reset LTR behavior */
}

.public-DraftEditor-content .align-justify .public-DraftStyleDefault-ltr {
  text-align: justify !important; /* Reset LTR behavior */
}
.role-editor > .ql-container {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  min-height: 2rem !important;
  height: 100%;
  width: 50% !important;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.role-editor > .ql-toolbar{
  width: 50%;
}